<template>
  <div>
    <Spinner v-if="isLoading" />
  </div>
</template>
<script>
import Spinner from '../components/Spinner.vue'
import { GET_DAC7_SELLER_REPORT } from '../store/actions.types'
import { GlobalPageManagerService } from '@rmp/services'

export default {
  title() {
    return 'DAC7'
  },

  components: {
    Spinner,
  },
  computed: {
    backHeadingLink() {
      return '/orders-and-invoices'
    },
    isLoading() {
      return this.$store.state.isLoading
    },
    dac7SellerReport() {
      return this.$store.getters.dac7SellerReport
    },
  },
  async mounted() {
    // Set back button
    GlobalPageManagerService.setPageConfig({
      backButton: {
        enabled: true,
        url: '/orders-and-invoices',
      },
    })

    await this.$store.dispatch(GET_DAC7_SELLER_REPORT)
    const report = this.dac7SellerReport
    window.location.href = report.url
  },
}
</script>

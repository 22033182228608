<template>
  <div class="reviewcard">
    <div class="reviewcard__grid">
      <!-- customer content -->
      <div class="reviewcard__grid__custcontent">
        <div class="reviewcard__grid__custcontent__container">
          <p
            class="reviewcard__grid__custcontent__heading"
            data-cy="reviewerName"
          >
            {{ review.customerFirstName }}
          </p>
          <p class="reviewcard__grid__bodytext--bold items-center inline-flex">
            <span class="leading-none" data-cy="userReviewMark">
              {{ review.ratingAverage }}
            </span>
            <pc-rating
              class="ml-1"
              :width="20"
              :max="maxRating"
              :rating="review.ratingAverage"
            />
          </p>
        </div>
      </div>

      <!-- meta content -->
      <div class="reviewcard__grid__metacontent">
        <div class="reviewcard__grid__metacontent__container">
          <!-- copy paste icon -->
          <div class="reviewcard__grid__metacontent__container__item1">
            <!-- date -->
            <p class="reviewcard__grid__bodytext--regular" data-cy="reviewDate">
              {{ format(new Date(review.reviewDate), 'd MMM yyyy') }}
            </p>
          </div>

          <!-- order link & copy/paste thing -->
          <div class="reviewcard__grid__metacontent__container__item2">
            <pie-link
              rel="noopener"
              tag="a"
              variant="default"
              data-cy="orderlink"
              :href="getOrderLink(review.orderNumber)"
            >
              {{ $t('order_n', { num: review.orderNumber }) }}
            </pie-link>
            <pie-icon-button
              @click="copyIdToClipboard(review.orderNumber)"
              size="small"
              variant="ghost-secondary"
            >
              <span
                v-pc-tooltip="{
                  placement: 'bottom',
                  content: $t('copy_order_number')
                }"
              >
                <icon-copy></icon-copy>
              </span>
            </pie-icon-button>
          </div>
        </div>
      </div>

      <!-- review area (including reply) -->
      <div class="reviewcard__grid__review">
        <div class="reviewcard__grid__review__comment">
          <p
            class="reviewcard__grid__bodytext--regular"
            data-cy="reviewerComment"
          >
            {{ review.customerComments }}
          </p>
        </div>
        <CommonThemes :review="review"></CommonThemes>
        <template v-if="isReplyEnabled">
          <div class="reviewcard__grid__review__reply">
            <div
              v-if="review.restaurantComments"
              class="reviewcard__grid__bodytext--regular reviewcard__grid__review__reply__container"
              data-cy="userReviewText"
            >
              <div
                class="reviewcard__grid__bodytext--bold reviewcard__grid__review__reply__header"
                data-cy="youRepliedText"
              >
                {{ $t('you_replied') }}
                <pie-tag
                  v-if="!review.restaurantCommentApproved"
                  v-pc-tooltip="{
                    placement: 'right',
                    content: $t('all_reviews_checked')
                  }"
                  class="reviewcard__grid__review__reply--pending"
                  data-cy="pendingApprovalText"
                  variant="neutral"
                  isStrong
                  :slot="$t('pending_approval')"
                  size="large"
                >
                  {{ $t('pending_approval') }}
                </pie-tag>
              </div>
              {{ review.restaurantComments }}
            </div>
            <review-response
              v-else
              :id="review.reviewId"
              class="reviewcard__grid__footer"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import '../styles/typography.css'
import '@justeattakeaway/pie-webc/components/icon-button.js'
import '@justeattakeaway/pie-icons-webc/dist/IconCopy.js'
import '@justeattakeaway/pie-webc/components/link.js'
import '@justeattakeaway/pie-webc/components/tag.js'
import { format } from 'date-fns'
import { PcRating } from '@je-pc/ui-components'
import ReviewResponse from './ReviewResponse'
import { getMaxRating } from '../utils/maxRating'
import CommonThemes from './CommonThemes.vue'

export default {
  data() {
    return {
      format
    }
  },
  props: {
    review: {
      type: Object,
      required: true
    },
    isReplyEnabled: {
      type: Boolean,
      required: true
    }
  },
  components: {
    PcRating,
    ReviewResponse,
    CommonThemes
  },
  computed: {
    maxRating() {
      return getMaxRating()
    }
  },
  methods: {
    getOrderLink(id) {
      return `/orders-and-invoices/order-history/${id}?referer=/reviews`
    },
    copyIdToClipboard(id) {
      navigator.clipboard.writeText(id)
    }
  }
}
</script>

<style scoped>
.reviewcard {
  position: relative;
  background-color: var(--dt-color-container-default);
  /* box-shadow: var(--dt-elevation-card); TODO: this is overridden by rmp-design-tokens.css in Partner Hub, have asked PH to update their package & will adjust here once done */
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.24),
    0px 1px 5px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: var(--dt-radius-rounded-c, 12px);
  padding: var(--dt-spacing-d, 16px);
  padding-bottom: 0px;
}

.reviewcard__grid {
  margin: 0px auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--dt-spacing-d, 16px);
  grid-template-areas:
    'custcontent'
    'metacontent'
    'review'
    'footer';
}

.reviewcard__grid__custcontent {
  grid-area: custcontent;
}

.reviewcard__grid__custcontent__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--dt-spacing-a, 4px);
}

.reviewcard__grid__metacontent {
  grid-area: metacontent;
}

.reviewcard__grid__metacontent__container {
  display: flex;
  flex-direction: column;
  gap: var(--dt-spacing-a, 4px);
}

.reviewcard__grid__metacontent__container__item2 {
  display: inline-flex;
  align-items: center;
  gap: var(--dt-spacing-a, 4px);
}

.reviewcard__grid__review {
  grid-area: review;
  display: flex;
  flex-direction: column;
  gap: var(--dt-spacing-d, 16px);
}

.reviewcard__grid__footer {
  grid-area: footer;
  display: flex;
  flex-direction: column;
  gap: var(--dt-spacing-d, 16px);
}

.reviewcard__grid__review__reply__header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.reviewcard__grid__review__reply__container {
  display: flex;
  flex-direction: column;
  background-color: var(--dt-color-container-subtle);
  border-radius: var(--dt-radius-rounded-c, 12px);
  padding: var(--dt-spacing-d, 16px);
  gap: var(--dt-spacing-a, 4px);
}

.container-inverse {
  background-color: var(--dt-color-container-inverse);
  color: var(--dt-color-container-default);
}

@media screen and (min-width: 1024px) {
  .reviewcard__grid {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'custcontent metacontent'
      'review review'
      'footer footer';
  }

  .reviewcard__grid__custcontent {
    grid-column: 1 / 2;
    grid-row: 1;
  }

  .reviewcard__grid__metacontent {
    grid-column: 2 / 3;
    grid-row: 1;
  }

  .reviewcard__grid__metacontent__container {
    align-items: flex-end;
  }

  .reviewcard__grid__review {
    grid-column: 1 / 3;
    grid-row: 2;
  }

  .reviewcard__grid__footer {
    grid-column: 1 / 3;
    grid-row: 3;
  }

  .reviewcard__grid__metacontent__container__item2 :nth-child(1) {
    order: 2;
  }

  .reviewcard__grid__metacontent__container__item2 :nth-child(2) {
    order: 1;
  }
}
</style>

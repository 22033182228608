<template>
  <div class="commonthemes">
    <!-- show all tags for desktop -->
    <div class="commonthemes__tagcontainer commonthemes__tagcontainer--showall">
      <div v-for="(item, index) in listofInsightTags" :key="index">
        <pie-tag
          :variant="item.value"
          size="large"
          value="positive"
          class="commonthemes__tagcontainer__tag"
        >
          <icon-thumbs-up v-if="item.value === 'success'"></icon-thumbs-up>
          <icon-thumbs-down v-else></icon-thumbs-down>
          {{ $t(item.key) }}
        </pie-tag>
      </div>
    </div>
    <!-- show extendible list for mobile -->
    <div
      v-if="onlyFew"
      class="commonthemes__tagcontainer commonthemes__tagcontainer--showmore"
    >
      <!-- Few tags less than 5 -->
      <div v-for="(item, index) in listofInsightTags" :key="index">
        <pie-tag
          v-if="index <= 3"
          :variant="item.value"
          size="large"
          value="positive"
          class="commonthemes__tagcontainer__tag"
        >
          <icon-thumbs-up v-if="item.value === 'success'"></icon-thumbs-up>
          <icon-thumbs-down v-else></icon-thumbs-down>
          {{ $t(item.key) }}
        </pie-tag>
      </div>
    </div>
    <!-- all tags -->
    <div v-else class="commonthemes__tagcontainer">
      <div v-for="(item, index) in listofInsightTags" :key="index">
        <pie-tag
          :variant="item.value"
          size="large"
          value="positive"
          class="commonthemes__tagcontainer__tag"
        >
          <icon-thumbs-up v-if="item.value === 'success'"></icon-thumbs-up>
          <icon-thumbs-down v-else></icon-thumbs-down>
          {{ $t(item.key) }}
        </pie-tag>
      </div>
    </div>
    <!-- show more/less link only if more than 4 tags available to display -->
    <div v-if="listofInsightTags.length > 4">
      <pie-link
        tag="button"
        size="small"
        variant="high-visibility"
        type="submit"
        v-on:click="showAllTags"
        v-if="showMoreButton"
        value="show more"
      >
        Show more
        <icon-chevron-down></icon-chevron-down>
      </pie-link>
      <pie-link
        tag="button"
        size="small"
        variant="high-visibility"
        type="submit"
        v-on:click="showAllTags"
        v-else
        value="show less"
      >
        Show less
        <icon-chevron-up></icon-chevron-up>
      </pie-link>
    </div>
  </div>
</template>
<script>
import '@justeattakeaway/pie-icons-webc/dist/IconThumbsDown'
import '@justeattakeaway/pie-icons-webc/dist/IconThumbsUp'
import '@justeattakeaway/pie-icons-webc/dist/IconChevronDown'
import '@justeattakeaway/pie-icons-webc/dist/IconChevronUp'

export default {
  data() {
    return {
      isLess: true,
      onlyFew: true,
      showMoreButton: true,
      listofInsightTags: this.review.insightClassifications
        ? this.formatInsightTags(this.review.insightClassifications)
        : null
    }
  },
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  methods: {
    showMore() {
      this.isLess = !this.isLess
    },
    showAllTags() {
      this.onlyFew = !this.onlyFew
      this.showMoreButton = !this.showMoreButton
    },
    formatInsightTags(classifications) {
      // loop through classifications & format for display
      var arrayOfKvObjects = Object.keys(classifications).reduce(
        (kvObj, key) => {
          var alwaysNegativeClassifications = [
            'FoodLeakedOrSpilled',
            'WrongOrMissingItems',
            'OrderNeverArrived'
          ]
          kvObj.push({
            key:
              classifications[key] === 0 ||
              (classifications[key] === 1 &&
                alwaysNegativeClassifications.includes(key))
                ? key.toLowerCase() + '_0_restaurant'
                : key.toLowerCase() + '_1_restaurant',
            value:
              classifications[key] === 0 ||
              (classifications[key] === 1 &&
                alwaysNegativeClassifications.includes(key))
                ? (classifications[key] = 'brand-05')
                : (classifications[key] = 'success')
          })
          return kvObj
        },
        []
      )
      // return sorted tags
      return (
        arrayOfKvObjects.sort((a, b) => a.key.localeCompare(b.key)).reverse() &&
        arrayOfKvObjects
          .sort((a, b) => a.value.localeCompare(b.value))
          .reverse()
      )
    }
  }
}
</script>
<style scoped>
.commonthemes {
  display: flex;
  flex-wrap: wrap;
  gap: var(--dt-spacing-c, 12px);
  flex-direction: column;
}

.commonthemes__tagcontainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--dt-spacing-b, 8px);
}

.commonthemes__tagcontainer__tag {
  flex: 0 0 auto;
}

.commonthemes__tagcontainer--showall {
  display: none;
}

@media screen and (min-width: 1024px) {
  pie-link[value='show more'] {
    display: none;
  }

  pie-link[value='show less'] {
    display: none;
  }

  .commonthemes__tagcontainer--showall {
    display: flex;
  }

  .commonthemes__tagcontainer--showmore {
    display: none;
  }
}
</style>
